import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { isJaguar } from "../utility/UserUtils";

class CardActionRequestChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      requestText: ''
    };
    this.requestChange = this.requestChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleReorderModal = this.toggleReorderModal.bind(this);
    this.editText = this.editText.bind(this);
    this.sendReorder = this.sendReorder.bind(this);
  }

  toggleModal() {
    const { setHandleClickOutside } = this.props;
    const { modalOpen } = this.state;
    if (modalOpen) {
      setHandleClickOutside(true);
    } else {
      setHandleClickOutside(false);
    }
    this.setState({ modalOpen: !modalOpen });
  }

  toggleReorderModal() {
    const { setHandleClickOutside } = this.props;
    const { reorderModalOpen } = this.state;
    if (reorderModalOpen) {
      setHandleClickOutside(true);
    } else {
      setHandleClickOutside(false);
    }
    this.setState({ reorderModalOpen: !reorderModalOpen, modalOpen: false });
  }

  editText(e) {
    this.setState({ requestText: e.target.value });
  }

  sendReorder() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/cards/${card.id}/reorder`, {
      method: "POST",
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log("Success!");
        // this.backReorderModal();
        location.reload();
      }else {
        console.log(json.error);
      }
    });
  }

  requestChange() {
    const { card } = this.props;
    const { requestText } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}/request_change`), {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({'request_text': requestText}),
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  renderModal() {
    const { card } = this.props;
    const { requestText } = this.state;
    return (
      <div style={{padding: "20px"}}>
        <div className="verdana-16-green">{`Change Request for ${card.name_first} ${card.name_last}`}</div>
        <br />
        <div className="form-field-group">
          <textarea value={requestText} onChange={ (e) => this.editText(e) }/>
        </div>
        <button className="default-button gillsans-14-white" onClick={this.requestChange}>Submit</button>
        <button className="default-button-cancel gillsans-14-black" onClick={this.toggleModal}>Cancel</button>
      </div>
    )
  }

  renderReorderModal() {
    const { card } = this.props;
    const { previewImage } = this.state;
    return (
      <div style={{padding: "40px 58px", textAlign: "center"}} className="card-table-actions-jaguar-view-wrapper">
        <div className="verdana-16-black" style={{marginBottom: "10px"}}>Are you sure you want to re-order this card?</div>
        <div className="verdana-14-grey" style={{marginBottom: "10px"}}>If you need to make any changes, use the <span className="verdana-14-orange">New Card</span> function.</div>
        <div className="notify-buttons" style={{display: "flex", marginTop: "20px", justifyContent: "center"}}>
          <div onClick={this.toggleReorderModal} style={{marginRight: "5px"}} className="default-button-mui-option default-button-mui-option-white gillsans-14-white">
            Back
          </div>
          <div style={{marginLeft: "5px"}} onClick={this.sendReorder} className="default-button-mui-option default-button-mui-option-orange gillsans-14-white">
            Re-Order
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { user, card } = this.props;
    const { modalOpen, reorderModalOpen } = this.state;
    if (!isJaguar(user) && card.status !== "approved") {
      return (
        <>
          <a onClick={this.toggleModal}>
            <div className="card-table-actions-popup-action">
              Request Change
            </div>
          </a>
          <Modal
            className="general-modal add-request-modal"
            overlayClassName="modal-overlay"
            isOpen={modalOpen}
            onRequestClose={this.toggleModal}
            contentLabel=""
            style={{backgroundColor: "#00BCEB"}}
          >
            {this.renderModal()}
          </Modal>
        </>
      );
    }
    if (card.status === "approved") {
      return (
        <>
          <a onClick={this.toggleReorderModal}>
            <div className="card-table-actions-popup-action">
              Re-order
            </div>
          </a>
          <Modal
            className="general-modal add-request-modal"
            overlayClassName="modal-overlay"
            isOpen={reorderModalOpen}
            onRequestClose={this.toggleReorderModal}
            contentLabel=""
            style={{backgroundColor: "#00BCEB"}}
          >
            {this.renderReorderModal()}
          </Modal>
        </>
      );
    }
    return '';
  }
}

export default CardActionRequestChange;
