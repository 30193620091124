import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";

class CardTableTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      trackingValue: ""
    };
    this.saveTrackingValue = this.saveTrackingValue.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.updateTrackingValue = this.updateTrackingValue.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  updateTrackingValue(e) {
    this.setState({ trackingValue: e.target.value });
  }

  saveTrackingValue() {
    const { card } = this.props;
    const { trackingValue } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}/update_tracking`), {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({'tracking_value': trackingValue}),
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  renderModal() {
    const { trackingValue } = this.state;
    return (
      <div style={{padding: "20px"}}>
        <div className="verdana-16-green">Add Tracking</div>
        <br />
        <div className="form-field-group">
          <input type="text" value={trackingValue} onChange={(e) => this.updateTrackingValue(e)} />
        </div>
        <button className="default-button gillsans-14-white" onClick={this.saveTrackingValue}>Save</button>
        <button className="default-button-cancel gillsans-14-black" onClick={this.toggleModal}>Cancel</button>
      </div>
    )
  }

  render() {
    const { modalOpen } = this.state;

    return (
      <>
        <div className="card-table-details-cell-specs-sub" onClick={this.toggleModal} style={{cursor: "pointer"}}><span className="verdana-11-grey">Tracking:</span> --</div>
        <Modal
          className="general-modal add-tracking-modal"
          overlayClassName="modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default CardTableTracking;
