import React from "react";
import PropTypes from "prop-types";
import { isJaguar } from "../utility/UserUtils";

class CardImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectImage: null,
      previewImage: props.previewImage,
      changed: false,
      modalOpen: false
    };
    this.setFiles = this.setFiles.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  setFiles(name, file, preview, tagList) {
    const { setImage } = this.props;
    if(file == null) {
      this.removeImage();
    }
    if(this.props.onDirty) {
      this.props.onDirty(file, preview, preview, tagList);
    }
    setImage(file);
    this.setState({
      projectImage: file,
      previewImage: preview
    });
  }

  renderMissingPreview() {
    if (window.location.href.includes('new')) {
      return (
        <img src={'/preview_fronts/new/new_card_front.png'} className="drop-section-preview" style={{width: "100%", height: "100%"}}/>
      );
    }
    return (
      <img src={'/preview_fronts/original/missing.png'} className="drop-section-preview" style={{width: "100%", height: "100%"}}/>
    );
  }

  render() {
    const { isFront, user } = this.props;
    const { previewImage, modalOpen } = this.state;
    let renderedImage = <></>;
    if (isFront) {
      renderedImage = (
        <>
          <div className="card-preview">
            <div className="drop-section-tiles">
              {
                previewImage ?
                <img src={previewImage} className="drop-section-preview" style={{width: "100%", height: "100%"}}/> :
                <>{this.renderMissingPreview()}</>
              }
            </div>
          </div>
          { previewImage ? "" : <div className="card-form-image-enlarge verdana-12-black">Front</div> }
        </>
      );
    } else {
      renderedImage = (
        <>
          <div className="card-preview">
            <div className="drop-section-tiles">
              <img src={this.props.previewImage ? this.props.previewImage : "/images/cards/default-card-back.png"} className="drop-section-preview" style={{width: "100%", height: "100%"}}/>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {renderedImage}
      </>
    );
  }
}

export default CardImageUpload;
