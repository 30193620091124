import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import UserTableAdd from "./UserTableAdd";

class UserTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: 'All Roles',
      dropdownOpen: false
    };
    this.searchBarInput = this.searchBarInput.bind(this);
    this.setSelectedRole = this.setSelectedRole.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  setSelectedRole(option) {
    const { setRole } = this.props;
    setRole(option);
    this.setState({ selectedRole: option });
  }

  searchBarInput(e) {
    const { setSearch } = this.props;
    setSearch(e.target.value);
  }

  renderDropdownOptions() {
    const { users } = this.props;
    const { dropdownOpen } = this.state;
    let years = users.map(option =>
      DateTime.fromFormat(option[1], "DD, t").year
    );
    years = years.filter(function(item, pos) {
        return years.indexOf(item) == pos;
    })
    const dropdownOptions = years.map(year =>
      <div className="toolbar-dropdown-option" key={`toolbar-dropdown-${year}`} onClick={() => this.setSelectedRole(year)}>{year}</div>
    );
    if (dropdownOpen) {
      return (
        <div className="toolbar-dropdown-options">
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedRole('All Roles')}>All Roles</div>
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedRole('user')}>User</div>
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedRole('gr_admin')}>GR - Admin</div>
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedRole('jaguar')}>Jaguar</div>
          {dropdownOptions}
        </div>
      );
    }
    return '';
  }

  render() {
    const { selectedRole } = this.state;
    return (
      <div className="mui-toolbar">
        <div className="toolbar-label verdana-11-black">Show:</div>
        <div className="toolbar-dropdown verdana-11-black" onClick={this.toggleDropdown}>
          {selectedRole}
          <svg height="6" width="9" style={{marginLeft: "8px"}}>
            <polygon points="0,0 9,0 4.5,6" style={{fill: "black"}}></polygon>
            Sorry, your browser does not support inline SVG.
          </svg>
          {this.renderDropdownOptions()}
        </div>
        <div className="toolbar-search">
          <input type="text" placeholder="Search" className="verdana-12-black" onChange={this.searchBarInput} />
        </div>
        <UserTableAdd />
      </div>
    );
  }
}

export default UserTableToolbar;
