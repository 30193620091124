import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { formatDate } from "../utility/DateUtils";
import { getStatusValue, cardBackOptions } from "../utility/CardUtils";

import Cookies from "universal-cookie";

import CardActionJaguarViewDetails from "./CardActionJaguarViewDetails";

class CardActionGRAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      changeModalOpen: false,
      changes: "",
      previewImage: props.card.thumb
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.sendApprove = this.sendApprove.bind(this);
    this.toggleChangeModal = this.toggleChangeModal.bind(this);
    this.backChangeModal = this.backChangeModal.bind(this);
    this.setChanges = this.setChanges.bind(this);
    this.sendChange = this.sendChange.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }
  toggleChangeModal() {
    const { changeModalOpen } = this.state;
    this.setState({ changeModalOpen: !changeModalOpen, modalOpen: false });
  }
  backChangeModal() {
    const { changeModalOpen } = this.state;
    this.setState({ changeModalOpen: false, modalOpen: true });
  }
  setChanges(e) {
    this.setState({ changes: e.target.value });
  }

  sendApprove() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/cards/${card.id}/approve`, {
      method: "POST",
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log("Success!");
        window.location.reload();
      }else {
        console.log(json.error);
      }
    });
  }

  sendChange() {
    const { card } = this.props;
    const { changes } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var data = new FormData();
    data.append("request_text",changes);
    fetch(`/cards/${card.id}/request_change`, {
      method: "POST",
      redirect: "manual",
      body: data,
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log("Success!");
        this.backChangeModal();
      }else {
        console.log(json.error);
      }
    });
  }

  renderCardDetails() {
    const { card } = this.props;
    const fieldsToRenderLeft = [
      { label: "Name", value: `${card.name_first} ${card.name_last}` },
      { label: "Job Title 1", value: card.job_title_1 },
      { label: "Job Title 2", value: card.job_title_2 },
      { label: "Email", value: card.email },
      { label: "Direct Line/VM", value: card.work_phone },
      { label: "Cell", value: card.cell_phone },
      { label: "Back of Card", value: cardBackOptions().find(option => option.value === card.card_back).label }
    ]
    const fieldsToRenderRight = [
      { label: "Branch", value: card.branch ? `${card.branch.name}` : '' },
      { label: "Address", value: card.branch ? `${card.branch.address}, ${card.branch.city} ${card.branch.state}, ${card.branch.zip}` : '' },
      { label: "Phone", value: card.branch ? `${card.branch.phone}` : '' },
      { label: "Fax", value: card.branch ? `${card.branch.fax}` : '' },
    ]
    const fieldsToRenderLower = [
      { label: "Notes", value: card.notes }
    ]

    const renderedDetailsLeft = fieldsToRenderLeft.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    const renderedDetailsRight = fieldsToRenderRight.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    const renderedDetailsLower = fieldsToRenderLower.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            {renderedDetailsLeft}
          </div>
          <div className="col-xs-12 col-sm-6">
            {renderedDetailsRight}
          </div>
          <div className="col-xs-12">
            {renderedDetailsLower}
          </div>
        </div>
      </div>
    );
  }

  renderModal() {
    const { card } = this.props;
    const { requestText, previewImage } = this.state;
    return (
      <div style={{padding: "40px 58px"}} className="card-table-actions-jaguar-view-wrapper">

        <div style={{display: "flex", marginBottom: "10px"}}>
          <div className="verdana-12-black">Status:&nbsp;</div>
          <div className={`status-wrapper status-wrapper-${card.status} verdana-11-white`}>
            {getStatusValue(card.status)}
          </div>
        </div>

        <div style={{width: "312px", margin: "auto", marginBottom: "15px", cursor: "pointer"}}>
          <div className="card-preview">
            <img src={previewImage} style={{position: "absolute", left: "0", top: "0", width: "100%"}}/>
          </div>
        </div>
        <div className="card-table-actions-jaguar-view-file" style={{marginBottom: "15px"}}>
          <div className="verdana-12-black">{card.filename}</div>
        </div>
        <div className="card-table-actions-jaguar-view-file" style={{marginBottom: "15px"}}>
          <a href={`/cards/${card.id}`}>
            <div className="verdana-12-orange">Edit Card</div>
          </a>
        </div>
        {this.renderCardDetails()}
        <br />
        <div style={{display: "flex", justifyContent: "center"}}>
          <a onClick={this.toggleChangeModal} style={{marginRight: "10px"}}>
            <div className="default-button-mui-option default-button-mui-option-white gillsans-14-white" style={{margin: "auto"}}>
              Request Change
            </div>
          </a>
          {
            card.status === "on_proof" ?
            <>
              <a onClick={this.sendApprove}>
                <div className="default-button-mui-option default-button-mui-option-orange gillsans-14-white" style={{margin: "auto"}}>
                  Approve
                </div>
              </a>
            </>
            :
            <a>
              <div className="default-button-mui-option default-button-mui-option-grey gillsans-14-white" style={{margin: "auto"}}>
                Approve
              </div>
            </a>
          }
        </div>
      </div>
    )
  }

  renderChangeModal() {
    const { card } = this.props;
    const { previewImage, changes } = this.state;
    return (
      <div style={{padding: "40px 58px", textAlign: "center"}} className="card-table-actions-jaguar-view-wrapper">
        <div className="verdana-16-black" style={{marginBottom: "10px"}}>Request your change here</div>
        <div style={{width: "312px", margin: "auto", marginBottom: "15px", cursor: "pointer"}}>
          <div className="card-preview">
            <img src={previewImage} style={{position: "absolute", left: "0", top: "0", width: "100%"}}/>
          </div>
        </div>
        <div>
          <textarea placeholder="changes here..." onChange={(e) => this.setChanges(e)} value={changes} />
        </div>
        <div className="notify-buttons" style={{display: "flex", marginTop: "20px", justifyContent: "center"}}>
          <div onClick={this.backChangeModal} style={{marginRight: "5px"}} className="default-button-mui-option default-button-mui-option-white gillsans-14-white">
            Back
          </div>
          <div onClick={this.sendChange} style={{marginLeft: "5px"}} className="default-button-mui-option default-button-mui-option-orange gillsans-14-white">
            Request Change
          </div>
        </div>
      </div>
    );
  }

  renderByType() {
    const { card, type } = this.props;
    if (type === "button") {
      return (
        <div className="card-table-actions-button-wrapper">
          <a onClick={this.toggleModal}><div className="default-button-mui-option gillsans-14-orange">View/Upload Proof</div></a>
        </div>
      );
    }
    if (type === "thumbnail") {
      return (
        <a onClick={this.toggleModal}>
          <img className="mui-preview-image" src={card.thumb} style={{cursor: "pointer"}}/>
        </a>
      )
    }
  }

  render() {
    const { card } = this.props;
    const { modalOpen, reorderModalOpen, changeModalOpen } = this.state;
    return (
      <>
        {this.renderByType()}
        <Modal
          className="general-modal add-request-modal"
          overlayClassName="modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderModal()}
        </Modal>
        <Modal
          className="general-modal add-request-modal"
          overlayClassName="modal-overlay"
          isOpen={changeModalOpen}
          onRequestClose={this.toggleChangeModal}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderChangeModal()}
        </Modal>
      </>
    );
  }
}

export default CardActionGRAdminView;
