import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class CardImagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    const { closeModal } = this.props;
    closeModal();
  }

  renderModal() {
    const { previewImage } = this.props;
    return (
      <img src={previewImage} className="card-form-preview-image" style={{maxWidth: "100%", height: "100%"}}/>
    );
  }

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        className="general-modal image-preview-modal"
        overlayClassName="modal-overlay"
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel=""
      >
        {this.renderModal()}
      </Modal>
    );
  }
}

export default CardImagePreview;
