
export function isJaguar(user) {
  return user.role === 'jaguar';
}
export function isGRAdmin(user) {
  return user.role === 'gr_admin';
}
export function isUser(user) {
  return user.role === 'user';
}

export function getRoleValue(role) {
  let value = role;
  switch(role) {
    case "user":
      value = "User";
      break;
    case "gr_admin":
      value = "GR - Admin";
      break;
    case "jaguar":
      value = "Jaguar";
      break;
    default:
  }
  return value;
}
