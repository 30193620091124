import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { dataURItoBlob } from "./utility/FileUtils";
import Cropper from "react-cropper";

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzoneText: "Select file or drop image here...",
      file: '',
      fileDropped: false,
      imagePreviewUrl: this.props.setImage,
      openModal: false,
      cropResult: ''
    };
    this.cropperRef = React.createRef();
    this.onDrop = this.onDrop.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
    this.closeComponent = this.closeComponent.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  onDrop(acceptedFiles) {
    const { name, setFiles, close } = this.props;
    const { file, cropResult } = this.state;

    if (acceptedFiles.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        this.setState({
          dropzoneText: "File attached",
          file: acceptedFiles[0],
          fileDropped: true,
          imagePreviewUrl: reader.result,
          // openModal: true
          cropResult: reader.result
        });

        let blob = dataURItoBlob(reader.result, acceptedFiles[0].name);
        setFiles(name, blob, reader.result, []);
      }
    } else {
      this.setState({
        dropzoneText: "This file type is not supported."
      });
    }
  }

  exitModal() {
    this.setState({ openModal: false });
  }

  onCropperInit(cropper) {
    this.cropper = cropper;
  }

  cropImage() {
    const { name, setFiles } = this.props;
    const { file, tagList, usingTags } = this.state;
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    let dataURL = this.cropper.getCroppedCanvas().toDataURL()
    this.setState({
      cropResult: dataURL,
      openModal: false
    });
    let tags = tagList;
    if (!usingTags) {
      tags = [];
    }
    let blob = dataURItoBlob(dataURL, file.name);
    setFiles(name, blob, dataURL, tags);
  }

  nextClicked() {
    const { name, setFiles, close } = this.props;
    const { file, cropResult } = this.state;
    let blob = dataURItoBlob(cropResult, file.name);
    setFiles(name, blob, cropResult, []);
    close();
  }

  closeComponent() {
    const { close } = this.props;
    close();
  }

  setDropzoneBeforeDrop() {
    const { setImage, profile_image } = this.props;
    const { dropzoneText, imagePreviewUrl } = this.state;
    if (setImage && imagePreviewUrl) {
      return (
        <>
          <img src={imagePreviewUrl} className="drop-section-preview" style={{width: "100%", minWidth: "100%", height: "100%"}}/>
        </>
      );
    }
    return (
      <>
        <img src="/preview_fronts/original/missing.png" className="drop-section-preview" style={{width: "100%", minWidth: "100%", height: "100%"}}/>
      </>
    );
  }

  showPreviews() {
    const { editCrop } = this.props;
    const { cropResult, file } = this.state;
    return (
      <object data={cropResult} width="100%" height="100%" type="application/pdf" />
    );
    return (
      <>
        <img src={cropResult} className="drop-section-preview" style={{maxWidth: "100%", minWidth: "100%", height: "100%"}}/>
      </>
    );
  }

  editCrop(e) {
    e.cancelBubble = true;
    e.stopPropagation();
    this.onDrop([this.state.file]);
  }

  renderCropper() {
    return (
      <Cropper
        src={this.state.imagePreviewUrl}
        style={{ height: "50vh", width: "100%",maxHeight:"50vh", maxWidth: "90vw", minWidth:"40vw" }}
        aspectRatio={this.props.freeRatio ? null : 3.5 / 2}
        preview=".img-preview"
        guides={true}
        viewMode={1}
        dragMode="move"
        autoCropArea={1}
        cropBoxMovable={false}
        onInitialized={this.onCropperInit.bind(this)}
      />
    );
  }

  renderModal() {
    return (
      <div className="sg-modal-cropper">
        <h4>Crop Image</h4>
        {this.renderCropper()}
        <div className="clear-div" />
        <button onClick={this.cropImage}>Upload</button>
        <button onClick={this.exitModal}>Cancel</button>
        <div className="clear-div" />
      </div>
    );
  }

  removeImage(e) {
    const { setFiles } = this.props;
    e.stopPropagation();
    this.setState({
      dropzoneText: " ",
      file: null,
      fileDropped: false,
      imagePreviewUrl: null,
      openModal: false
    });
    setFiles(null, null, null, null);
  }

  renderChangeRemoveOptions() {
    const { imagePreviewUrl } = this.state;
    if(imagePreviewUrl) {
      return (
        <div className="change-remove-options">
          Change | <div style={{display: "inline-block"}} onClick={this.removeImage}>Remove</div>
        </div>
      )
    }
    return (
      <div className="change-remove-options">
        {this.props.customUploadPhotoText ? this.props.customUploadPhotoText :"Upload Photo"}
      </div>
    )
  }

  render() {
    const { showPreviews, fullWidth } = this.props;
    const { imagePreviewUrl, cropResult, file, fileDropped, dropzoneText, openModal } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onDrop} name="hero_image[image]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
              <div {...getRootProps()} className={fullWidth ? "drop-section-tiles-full" : "drop-section-tiles"}>
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                {fileDropped && showPreviews ? (
                  this.showPreviews()
                ) : (
                  this.setDropzoneBeforeDrop()
                )}
              </div>
              <div className="dropzone-image-buttons">
                <button className="default-button-cancel gillsans-14-black" style={{marginLeft: "0px"}} onClick={this.closeComponent}>Cancel</button>
                <button className="default-button gillsans-14-white" style={{float: "right"}} onClick={this.nextClicked}>Next</button>
              </div>
              </>
            );
          }}
        </Dropzone>
        <Modal
          className="general-modal"
          overlayClassName="modal-overlay"
          isOpen={openModal}
          contentLabel="View Image"
        >
          {this.renderModal()}
        </Modal>
      </>
    );
   }
}

export default DropzoneComponent;
