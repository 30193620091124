import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";

class UserTableEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      email: props.user.email,
      role: props.user.role
    };
    this.deleteUser = this.deleteUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  updateEmail(e) {
    this.setState({ email: e.target.value });
  }
  updateRole(e) {
    this.setState({ role: e.target.value });
  }

  saveUser() {
    const { user } = this.props;
    const { email, role } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/users/${user.id}`), {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify({'email': email, 'role': role}),
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  deleteUser() {
    const { user } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/users/${user.id}`), {
      method: 'DELETE',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  renderModal() {
    const { email, role } = this.state;
    return (
      <div style={{padding: "20px"}}>
        <div className="verdana-16-green">Edit User</div>
        <br />
        <div className="form-field-group">
          <label>Email Address</label>
          <input type="text" value={email} onChange={(e) => this.updateEmail(e)} />
        </div>
        <div className="form-field-group">
          <label>Role</label>
          <select value={role} onChange={(e) => this.updateRole(e)}>
            <option value="jaguar">jaguar</option>
            <option value="gr_admin">gr_admin</option>
            <option value="user">user</option>
          </select>
        </div>
        <button className="default-button gillsans-14-white" onClick={this.saveUser}>Save</button>
        <button className="default-button-delete gillsans-14-white" onClick={this.deleteUser}>Delete User</button>
        <button className="default-button-cancel gillsans-14-black" onClick={this.toggleModal}>Cancel</button>
      </div>
    )
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <a onClick={this.toggleModal} style={{cursor: "pointer"}}>Edit</a>
        &nbsp;|&nbsp;
        <a onClick={this.deleteUser} style={{cursor: "pointer"}}>Remove</a>
        <Modal
          className="general-modal add-tracking-modal"
          overlayClassName="modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserTableEdit;
