import React from "react";
import PropTypes from "prop-types";
import { isJaguar, isGRAdmin } from "../utility/UserUtils";
import { DateTime } from "luxon";
import { formatDate, orderByDateDescending } from "../utility/DateUtils";

class CardHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderHistory() {
    const { history } = this.props;

    const orderedHistory = orderByDateDescending(history);

    const renderedHistory = orderedHistory.map(value =>
      <div className="card-history-wrapper">
        <div className="card-history-date verdana-12-lightgrey-italic">{formatDate(value.created_at)}</div>
        <div className="card-history-message verdana-12-black-italic" dangerouslySetInnerHTML={{__html: value.message}} />
      </div>
    );
    return renderedHistory;
  }

  render() {
    const { user } = this.props;
    if (isJaguar(user) || isGRAdmin(user)) {
      return (
        <>
          <div className="verdana-12-black">History:</div>
          <div className="card-histories">
            {this.renderHistory()}
          </div>
        </>
      );
    }
    return <></>;
  }
}

export default CardHistory;
