import { createMuiTheme } from '@material-ui/core/styles';

export function getMuiTheme() {
  return (
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveBase: {
            overflow: "visible"
          }
        },
        MuiTableCell: {
          head: {
            backgroundColor: "#F2F2F2",
            padding: "0px auto"
          },
          root: {
            fontSize: "11px",
            fontFamily: "Verdana",
            color: "#000000",
            padding: "16px 0px",
          }
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#F2F2F2",
            height: "auto"
          },
        },
        MuiButton: {
          text: {
            padding: "0px 8px",
            fontSize: "11px",
            fontFamily: "Verdana",
            color: "#8B8B8B",
          },
        },
      },
    })
  );
}
