import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { formatDate } from "../utility/DateUtils";
import { getStatusValue, cardBackOptions } from "../utility/CardUtils";

import DropzoneComponent from "../DropzoneComponent";
import Cookies from "universal-cookie";

import CardActionJaguarViewDetails from "./CardActionJaguarViewDetails";

class CardActionJaguarView extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const card_id = urlParams.get('card_id');

    this.state = {
      modalOpen: card_id !== null && card_id === props.card.id.toString() && props.modalOnLoad ? true : false,
      notifyModalOpen: false,
      customMessage: "",
      previewImage: props.card.thumb
    };
    this.setAndUploadFiles = this.setAndUploadFiles.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleNotifyModal = this.toggleNotifyModal.bind(this);
    this.backNotifyModal = this.backNotifyModal.bind(this);
    this.sendNotify = this.sendNotify.bind(this);
    this.updateCustomMessage = this.updateCustomMessage.bind(this);
  }

  updateCustomMessage(e) {
    this.setState({customMessage: e.target.value});
  }

  formUrl() {
    const { card } = this.props;
    if (card !== undefined) {
      return `/cards/${card.id}/upload_file`;
    }
    return '/cards';
  }

  setAndUploadFiles(name, file, preview, tagList) {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var data = new FormData();
    if (file !== null) {
      data.append("card[preview_front]", file, file.name)
    }
    fetch(this.formUrl(), {
      method: "POST",
      redirect: "manual",
      body: data,
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log("Success!");
        window.location.href = `/?card_id=${card.id}`;
      }else {
        console.log(json.error);
      }
    });
  }

  sendNotify() {
    const { card } = this.props;
    const { customMessage } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var data = new FormData();
    data.append("custom_message", customMessage);
    fetch(`/cards/${card.id}/notify`, {
      method: "POST",
      redirect: "manual",
      body: data,
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log("Success!");
        this.backNotifyModal();
      }else {
        console.log(json.error);
      }
    });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  toggleNotifyModal() {
    const { notifyModalOpen } = this.state;
    this.setState({notifyModalOpen: !notifyModalOpen, modalOpen: false});
  }
  backNotifyModal() {
    const { notifyModalOpen } = this.state;
    this.setState({notifyModalOpen: false, modalOpen: true});
  }

  renderChanges() {
    const { card } = this.props;
    const comments = card.card_comments.map(comment =>
      <div style={{marginBottom: "10px"}} key={`card-${card.id}-comment-${comment.id}`}>
        <div className="card-info-value-comment-timestamp">{formatDate(comment.created_at)}</div>
        <div className="card-info-value-comment-value">{comment.message}</div>
      </div>
    );
    return comments;
  }

  renderHistory() {
    const { card } = this.props;
    const histories = card.card_histories.reverse().map(history =>
      <div style={{marginBottom: "10px"}} key={`card-${card.id}-comment-${history.id}`}>
        <div className="card-info-value-comment-timestamp">{formatDate(history.created_at)}</div>
        <div className="card-info-value-comment-value">{history.message}</div>
      </div>
    );
    return histories;
  }

  renderCardDetails() {
    const { card } = this.props;
    const fieldsToRenderLeft = [
      { label: "Name", value: `${card.name_first} ${card.name_last}` },
      { label: "Job Title 1", value: card.job_title_1 },
      { label: "Job Title 2", value: card.job_title_2 },
      { label: "Email", value: card.email },
      { label: "Direct Line/VM", value: card.work_phone },
      { label: "Cell", value: card.cell_phone },
      { label: "Back of Card", value: cardBackOptions().find(option => option.value === card.card_back).label }
    ]
    const fieldsToRenderRight = [
      { label: "Branch", value: card.branch ? `${card.branch.name}` : '' },
      { label: "Address", value: card.branch ? `${card.branch.address}, ${card.branch.city} ${card.branch.state}, ${card.branch.zip}` : '' },
      { label: "Phone", value: card.branch ? `${card.branch.phone}` : '' },
      { label: "Fax", value: card.branch ? `${card.branch.fax}` : '' },
    ]
    const fieldsToRenderLower = [
      { label: "Notes", value: card.notes }
    ]

    const renderedDetailsLeft = fieldsToRenderLeft.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    const renderedDetailsRight = fieldsToRenderRight.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    const renderedDetailsLower = fieldsToRenderLower.map(field =>
      <CardActionJaguarViewDetails card={card} field={field} key={`jaguar-view-${field.label}`}/>
    );
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            {renderedDetailsLeft}
          </div>
          <div className="col-xs-12 col-sm-6">
            {renderedDetailsRight}
          </div>
          <div className="col-xs-12">
            {renderedDetailsLower}
          </div>
        </div>
      </div>
    );
  }

  renderModal() {
    const { card } = this.props;
    const { requestText, previewImage } = this.state;
    return (
      <div style={{padding: "40px 58px"}} className="card-table-actions-jaguar-view-wrapper">

        <div style={{display: "flex", marginBottom: "10px"}}>
          <div className="verdana-12-black">Status:&nbsp;</div>
          <div className={`status-wrapper status-wrapper-${card.status} verdana-11-white`}>
            {getStatusValue(card.status)}
          </div>
        </div>

        <div style={{width: "312px", margin: "auto", marginBottom: "15px", cursor: "pointer"}}>
          <div className="card-preview">
            <DropzoneComponent key={`card-${card.id}-dropzone`} freeRatio={false} showOptions={true} name="drawing" setFiles={this.setAndUploadFiles} showPreviews={true} editCrop={true} setImage={previewImage} />
          </div>
        </div>
        <div className="card-table-actions-jaguar-view-file" style={{marginBottom: "15px"}}>
          <div className="verdana-12-black">{card.filename}</div>
          <div className=""><a href={card.preview_front} target="_blank" download className="verdana-12-orange">Download Proof</a></div>
        </div>
        <div className="card-table-actions-jaguar-view-file" style={{marginBottom: "15px"}}>
          <a href={`/cards/${card.id}`}>
            <div className="verdana-12-orange">Edit Card</div>
          </a>
        </div>
        {this.renderCardDetails()}
        <br />
        {
          card.card_comments.length > 0 ?
          <div className="card-info-wrapper">
            <div className="card-info-label verdana-12-grey">Changes</div>
            <div className="card-info-value verdana-12-black" style={{cursor: "default"}}>{this.renderChanges()}</div>
          </div>
          :
          <></>
        }
        {
          card.card_histories.length > 0 ?
          <div className="card-info-wrapper">
            <div className="card-info-label verdana-12-grey">History</div>
            <div className="card-info-value card-info-history verdana-12-black" style={{cursor: "default"}}>{this.renderHistory()}</div>
          </div>
          :
          <></>
        }
   
        <div onClick={this.toggleNotifyModal} className="default-button-mui-option default-button-mui-option-orange gillsans-14-white" style={{margin: "auto"}}>
          Notify
        </div>
      </div>
    )
  }

  renderNotifyModal() {
    const { card } = this.props;
    return (
      <div style={{padding: "40px 58px", textAlign: "center"}} className="card-table-actions-jaguar-view-wrapper">
        <div className="verdana-16-black" style={{marginBottom: "10px"}}>A notification will be sent to:</div>
        <div className="verdana-16-black" style={{marginBottom: "10px"}}>{card.email}</div>
        <div className="verdana-14-black" style={{marginBottom: "10px"}}>Add custom message (optional)</div>
        <div className="form-field-group">
          <textarea type="text-area" placeholder="Enter Message Here" className="verdana-12-black" style={{padding: "15px"}} onChange={this.updateCustomMessage} />
        </div>

        <div className="verdana-14-grey" style={{fontStyle: "italic"}}>Graniterock Admins and Jaguars are automatically copied.</div>

        <div className="notify-buttons" style={{display: "flex", marginTop: "20px", justifyContent: "center"}}>
          <div onClick={this.backNotifyModal} style={{marginRight: "5px"}} className="default-button-mui-option default-button-mui-option-white gillsans-14-white">
            Back
          </div>
          <div style={{marginLeft: "5px"}} onClick={this.sendNotify} className="default-button-mui-option default-button-mui-option-orange gillsans-14-white">
            Send
          </div>
        </div>
      </div>
    );
  }

  renderByType() {
    const { card, type } = this.props;
    if (type === "button") {
      return (
        <div className="card-table-actions-button-wrapper" key={`card-${card.id}-button`}>
          <a onClick={this.toggleModal}><div className="default-button-mui-option gillsans-14-orange">View/Upload Proof</div></a>
        </div>
      );
    }
    if (type === "thumbnail") {
      return (
        <a onClick={this.toggleModal} key={`card-${card.id}-thumbnail`}>
          <img className="mui-preview-image" src={card.thumb} style={{cursor: "pointer"}}/>
        </a>
      )
    }
  }

  render() {
    const { card } = this.props;
    const { modalOpen, notifyModalOpen } = this.state;
    return (
      <>
        {this.renderByType()}
        <Modal
          className="general-modal add-request-modal"
          overlayClassName="modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
          key={`card-${card.id}-modal`}
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderModal()}
        </Modal>
        <Modal
          className="general-modal add-request-modal"
          overlayClassName="modal-overlay"
          isOpen={notifyModalOpen}
          onRequestClose={this.toggleNotifyModal}
          contentLabel=""
          key={`card-${card.id}-notify-modal`}
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderNotifyModal()}
        </Modal>
      </>
    );
  }
}

export default CardActionJaguarView;
