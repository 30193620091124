import { DateTime } from "luxon";

export function formatDate(dateISOString) {
  const date = DateTime.fromISO(dateISOString);
  return date.toFormat("DD, t");
}

export function formatDateMonthYear(dateISOString) {
  const date = DateTime.fromISO(dateISOString);
  return date.toFormat("LLL, yyyy");
}

export function formatDateYear(dateISOString) {
  const date = DateTime.fromISO(dateISOString);
  return date.toFormat("yyyy");
}

export function orderByDateDescending(list) {
  list.sort( (a,b) => new Date(b.created_at) - new Date(a.created_at));
  return list;
}
