
export function getStatusValue(status) {
  let value = status;
  switch(status) {
    case "new_card":
      value = "New";
      break;
    case "on_proof":
      value = "On Proof";
      break;
    case "change":
      value = "Change";
      break;
    case "approved":
      value = "Approved";
      break;
    default:
  }
  return value;
}

export function isNewCard(status) {
  return status === "new_card";
}
export function isOnProof(status) {
  return status === "on_proof";
}
export function isChanged(status) {
  return status === "change";
}
export function isApproved(status) {
  return status === "approved";
}

export function cardBackOptions() {
  return [
    {value: 0, label: "Standard", image: "/images/cards/default-card-back.png"},
    {value: 1, label: "Building Materials", image: "/images/cards/bm-card-back.png"},
    {value: 2, label: "Careers", image: "/images/cards/careers-card-back.png"}
  ];
}
