import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DateTime } from "luxon";

import CardTableTracking from "./CardTableTracking";
import CardActionButton from "./CardActionButton";
import CardTableToolbar from "./CardTableToolbar";
import CardTableFooter from "./CardTableFooter";
import CardActionJaguarView from './CardActionJaguarView';
import CardActionUserView from './CardActionUserView';
import CardActionGRAdminView from './CardActionGRAdminView';

import { getStatusValue } from "../utility/CardUtils";
import { getMuiTheme } from "../utility/MuiUtils";
import { formatDate, formatDateYear } from "../utility/DateUtils";
import { isJaguar, isUser, isGRAdmin } from "../utility/UserUtils";

class CardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizedCards: [],
      rowsPerPage: 5,
      pageNumber: 0,
      years: []
    };
    this.setSearch = this.setSearch.bind(this);
    this.setYear = this.setYear.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
  }

  componentDidMount() {
    const { cards } = this.props;
    const years = [];
    let organizedCards = this.mapCardsToColumns(cards);
    cards.forEach(card => {
      years.push(formatDateYear(card.created_at))
    });
    this.setState({
      organizedCards: organizedCards,
      filteredCards: organizedCards,
      years: [...new Set(years)]
    });
  }

  mapCardsToColumns(cards) {
    return (
      cards.map(card =>
        [
          {key: `render-thumb-card-${card.id}`, value: card.thumb ? this.renderPreview(card.thumb, card.full_image, card) : <div type="thumbnail" key={`card-${card.id}-thumbnail`}/>},
          {key: `render-details-card-${card.id}`, value: this.renderCardDetails(card)},
          {key: `render-action-card-${card.id}`, value: this.renderCardActions(card)},
        ]
      )
    );
  }

  setYear(option) {
    const { organizedCards } = this.state;
    const filteredCards = [];
    if (option === "All Years") {
      this.setState({ filteredCards: organizedCards });
    } else {
      organizedCards.forEach(card => {
        if (card[3].includes(option)) {
          filteredCards.push(card);
        }
      });
      this.setState({ filteredCards: filteredCards });
    }
  }

  setRowsPerPage(option) {
    this.setState({
      rowsPerPage: option,
      pageNumber: 0
    });
  }

  setPageNumber(option) {
    this.setState({ pageNumber: option });
  }

  setSearch(value) {
    const { cards } = this.props;
    const { organizedCards } = this.state;
    const filteredCards = [];
    cards.forEach(card => {
      let toBeAdded = true;
      Object.entries(card).forEach(field => {
        if (toBeAdded) {
          try {
            if (field[1].toString().toLowerCase().includes(value.toString().toLowerCase())) {
              filteredCards.push(card);
              toBeAdded = false;
            }
          } catch (error) {
          }
        }
      });
    });
    this.setState({ filteredCards: this.mapCardsToColumns(filteredCards) });
  }

  renderPreview(preview, url, card) {
    const { user } = this.props;
    if (isUser(user)) {
      return (
        <CardActionUserView card={card} type="thumbnail" key={`card-${card.id}-thumbnail`}/>
      )
    }
    if (isGRAdmin(user)) {
      return (
        <CardActionGRAdminView card={card} type="thumbnail" key={`card-${card.id}-thumbnail`}/>
      );
    }
    return (
      <CardActionJaguarView card={card} type="thumbnail" key={`card-${card.id}-thumbnail`} modalOnLoad={false}/>
    );
  }

  renderTracking(card) {
    const { user } = this.props;
    if (isJaguar(user)) {
      return (
        <>
        {
          card.tracking === '' ?
          <CardTableTracking card={card} key={`card-table-tracking-${card.id}`} />
          :
          <div className="card-table-details-cell-specs-sub verdana-11-orange"><span className="verdana-11-grey">Tracking:</span> {card.tracking}</div>
        }
        </>
      )
    }
    return '';
  }

  renderCardDetails(card) {
    return (
      <div className="card-table-details-cell-wrapper" key={`card-table-details-cell-wrapper-${card.id}`}>
        <div className="card-table-details-cell-title verdana-16-black">
          {`${card.name_first} ${card.name_last}`}
        </div>
        {this.renderStatus(card.status)}
        <div className="card-table-details-cell-specs verdana-11-black">
          <div className="card-table-details-cell-specs-inline">
            <div className="card-table-details-cell-specs-sub"><span className="verdana-11-grey">Updated on:</span> {formatDate(card.updated_at)}</div>
            <div className="card-table-details-cell-specs-sub"><span className="verdana-11-grey">Quantity:</span> {card.quantity}</div>
          </div>
          <div className="card-table-details-cell-specs-inline card-table-details-cell-specs-inline-spacer" />
          <div className="card-table-details-cell-specs-inline">
          <div className="card-table-details-cell-specs-sub"><span className="verdana-11-grey">Order by:</span> {card.created_by}</div>
          {this.renderTracking(card)}
          </div>
        </div>
      </div>
    );
  }

  renderCardActions(card) {
    const { user } = this.props;
    return (
      <CardActionButton card={card} user={user} key={`card-${card.id}-actions`}/>
    );
  }

  renderStatus(status) {
    return (
      <div className={`status-wrapper status-wrapper-${status} verdana-11-white`}>
        {getStatusValue(status)}
      </div>
    );
  }

  renderToolbar(props) {
    const { organizedCards, years } = this.state;
    return <CardTableToolbar {...props} key={`card-table-toolbar`} value={2} setYear={this.setYear} setSearch={this.setSearch} cards={organizedCards} years={years} />;
  }
  renderFooter(props) {
    const { organizedCards, pageNumber } = this.state;
    return <CardTableFooter {...props} key={`card-table-footer`} value={2} setRowsPerPage={this.setRowsPerPage} setPageNumber={this.setPageNumber} pageNumber={pageNumber} cards={organizedCards} />;
  }

  renderEmptyTable () {
    return (
      <>
        <div className="verdana-16-black empty-table-top">You have not ordered any cards.</div>
        <div className="verdana-14-black empty-table-bottom">
          To get started click the <span className="verdana-14-orange">New Card</span> button above.
        </div>
      </>
    )
  }

  render() {
    const { filteredCards, rowsPerPage, pageNumber } = this.state;

    const columns = [
      {
        name: "Preview",
        label: "Preview",
        options: {
          setCellProps: () => ({ style: { width: "240px" }}),
          customHeadRender: ()=>{},
          customBodyRender: (data, type, row) => {
            return <React.Fragment key={data.key}>{data.value}</React.Fragment>
          }
        }
      },
      {
        name: "First",
        label: "First",
        options: {
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {
            return <React.Fragment key={data.key}>{data.value}</React.Fragment>
          }
        }
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          setCellProps: () => ({ style: { width: "120px" }}),
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {
            return <React.Fragment key={data.key}>{data.value}</React.Fragment>
          }
        }
      }
    ];

    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={filteredCards}
          columns={columns}
          options={{
            selectableRows: 'none',
            search: true,
            searchOpen: true,
            rowsPerPage: rowsPerPage,
            page: pageNumber,
            textLabels: {
              body: {
                noMatch: <div key="card-table-empty">{this.renderEmptyTable()}</div>
              }
            },
          }}
          components={{
            TableToolbar: this.renderToolbar,
            TableFooter: this.renderFooter
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default CardTable;
