import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

class CardTableFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  setItemsPerPage(option) {
    const { setRowsPerPage } = this.props
    setRowsPerPage(option);
  }

  setPage(page) {
    const { setPageNumber, rowCount, rowsPerPage } = this.props;
    const totalPages = Math.ceil(rowCount / rowsPerPage);
    if ( page >= 0 && page < totalPages ) {
      setPageNumber(page);
    }
  }

  renderDropdownOptions() {
    const { dropdownOpen } = this.state;
    let options = [1, 5, 10, 20, 50];
    const dropdownOptions = options.map(option =>
      <div className="toolbar-dropdown-option" key={`toolbar-dropdown-${option}`} onClick={() => this.setItemsPerPage(option)}>{option}</div>
    );
    if (dropdownOpen) {
      return (
        <div className="toolbar-dropdown-options">
          {dropdownOptions}
        </div>
      );
    }
    return '';
  }

  renderPagePicker() {
    const { rowsPerPage, rowCount, pageNumber } = this.props;
    const totalPages = Math.ceil(rowCount / rowsPerPage);
    const pageItems = [...Array(totalPages)].map((_, page) => {
      return (
        <div key={`page-selection-button-${page}`} className={`page-selection-button verdana-11-orange ${page === pageNumber ? "page-selection-button-selected" : ""}`} onClick={() => this.setPage(page)}>{page + 1}</div>
      );
    });
    return (
      <div className="page-selection-wrapper">
        <div className="page-selection-button verdana-11-orange" onClick={() => this.setPage(0)} style={{marginRight: "13px"}}>First</div>
        <div className="page-selection-button verdana-11-orange" onClick={() => this.setPage(pageNumber - 1)}>
          <svg height="9" width="6">
            <polygon points="6,0 6,9 0,4.5" style={{fill: "#F57B20"}}></polygon>
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        {pageItems}
        <div className="page-selection-button verdana-11-orange" onClick={() => this.setPage(pageNumber + 1)}>
          <svg height="9" width="6">
            <polygon points="0,0 6,4.5 0,9" style={{fill: "#F57B20"}}></polygon>
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <div className="page-selection-button verdana-11-orange" onClick={() => this.setPage(totalPages - 1)} style={{marginLeft: "13px"}}>Last</div>
      </div>
    )
  }

  render() {
    const { rowsPerPage, rowCount } = this.props;
    const { selectedYear } = this.state;

    return (
      <div className="mui-toolbar">
        {this.renderPagePicker()}
        <div className="items-per-page-wrapper gillsans-14-white">
          <div className="toolbar-label verdana-11-black">Items per page:</div>
          <div className="toolbar-dropdown verdana-11-black" onClick={this.toggleDropdown}>
            {rowsPerPage}
            <svg height="6" width="9" style={{marginLeft: "8px"}}>
              <polygon points="0,0 9,0 4.5,6" style={{fill: "black"}}></polygon>
              Sorry, your browser does not support inline SVG.
            </svg>
            {this.renderDropdownOptions()}
          </div>
        </div>
      </div>
    );
  }
}

export default CardTableFooter;
