import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Select, { createFilter } from 'react-select';

import CardImageUpload from "./CardImageUpload";
import CardImagePreview from "./CardImagePreview";
import CardHistory from "./CardHistory";
import { getStatusValue, cardBackOptions } from "../utility/CardUtils";
import { isJaguar, isUser } from "../utility/UserUtils";

class CardForm extends React.Component {
  constructor(props) {
    super(props);
    const {card} = this.props;
    this.state = {
      checkError: false,
      formError: null,
      modalOpen: false,
      imageFront: null,
      imageBack: props.card && props.card.card_back ? cardBackOptions().find(option => option.value === props.card.card_back).image : null,
      cardBack: props.card && props.card.card_back ? props.card.card_back : 0,
      previewModalOpen: false,
      cardBackOpen: false,
      submitting: false,
      initialValues: {
        card: {
          name_first: card ? card.name_first : "",
          name_last: card ? card.name_last : "",
          job_title_1: card ? card.job_title_1 : "",
          job_title_2: card ? card.job_title_2 : "",
          email: card ? card.email : "",
          work_phone: card ? card.work_phone : "",
          work_phone_area: card && card.work_phone ? card.work_phone.split('.')[0] : "",
          work_phone_first: card && card.work_phone ? card.work_phone.split('.')[1] : "",
          work_phone_last: card && card.work_phone ? card.work_phone.split('.')[2] : "",
          cell_phone: card ? card.cell_phone : "",
          cell_phone_area: card && card.cell_phone ? card.cell_phone.split('.')[0] : "",
          cell_phone_first: card && card.cell_phone ? card.cell_phone.split('.')[1] : "",
          cell_phone_last: card && card.cell_phone ? card.cell_phone.split('.')[2] : "",
          branch_id: card ? (card.branch ? card.branch.id : "" ): "",
          notes: card ? card.notes : "",
          quantity: card ? card.quantity : 250,
          tracking: card ? card.tracking : ""
        }
      }
    };
    this.wrapperRef = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePreviewModal = this.togglePreviewModal.bind(this);
    this.imageFrontSet = this.imageFrontSet.bind(this);
    this.imageBackSet = this.imageBackSet.bind(this);
    this.toggleCardBack = this.toggleCardBack.bind(this);
    this.setCardBack = this.setCardBack.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.formikSubmit = this.formikSubmit.bind(this);
    this.validateNumber = this.validateNumber.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef && !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({cardBackOpen: false});
    }
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  togglePreviewModal() {
    const { previewModalOpen } = this.state;
    this.setState({previewModalOpen: !previewModalOpen});
  }

  imageFrontSet(image) {
    this.setState({
      imageFront: image
    });
  }

  imageBackSet(image) {
    this.setState({
      imageBack: image
    });
  }

  toggleCardBack() {
    const { cardBackOpen } = this.state;
    this.setState({ cardBackOpen: !cardBackOpen });
  }

  setCardBack(option) {
    this.setState({
      cardBack: option.value,
      imageBack: option.image
    });
  }

  submitForm(formikProps) {
    const { values } = formikProps;
    if(
      values['card']['name_first'] === "" ||
      values['card']['name_last'] === "" ||
      values['card']['job_title_1'] === "" ||
      values['card']['email'] === "" ||
      values['card']['branch_id'] === "" ||
      values['card']['quantity'] === ""
    ) {
      this.setState({
        formError: "Please fill out all required fields.",
        checkError: true
      })
    } else {
      this.setState({
        formError: null,
        checkError: true,
        modalOpen: true
      })
      console.log("checks passed. Submitting");
    }
  }

  formikSubmit(formikProps) {
    const { submitting } = this.state;
    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    formikProps.handleSubmit()
  }

  cancelForm(formikProps) {
    window.location = "/"
  }

  formUrl() {
    const { card } = this.props;
    if (card !== undefined) {
      return `/cards/${card.id}`;
    }
    return '/cards';
  }

  formMethod() {
    const { card } = this.props;
    if (card !== undefined) {
      return 'PATCH';
    }
    return 'POST';
  }

  getLabelName(key) {
    const label_names = {
      name_first: "First Name",
      name_last: "Last Name",
      job_title_1: "Job Title 1",
      job_title_2: "Job Title 2",
      email: "Email",
      work_phone: "Direct Line/VM",
      cell_phone: "Cell",
      branch_id: "Branch",
      notes: "Notes",
      quantity: "Quantity",
      tracking: "Tracking"
    };
    return label_names[key];
  }

  getValue(value, key) {
    if (key === "branch_id") {
      let branch = this.getBranchOptions().filter(option => option.value === value)[0];
      if (branch !== undefined) {
        return branch["label"];
      }
    }
    return value;
  }

  getBranchOptions() {
    const { branches } = this.props;
    const branchOptions = branches.map(branch => {
      return ({ label: `${branch.name}: ${branch.address}, ${branch.city} ${branch.state}, ${branch.zip}`, value: branch.id });
    });
    return branchOptions;
  }

  renderField(name, formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;

    if (this.readonly()) {
      return (
        <Field placeholder=" " className={`form-control ${erroring ? 'field-erroring': "" }`} readonly="readonly" type={properties['type']} name={`card[${name}]`}/>
      );
    }

    let erroring = checkError && (formikProps.values.card[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field placeholder=" " onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`card[${name}]`}/>
    )
  }

  validateNumber(evt, name, specificName, formikProps, length) {
    const { card } = formikProps.values;
    var theEvent = evt || window.event;

    let key = evt.target.value
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if (
        key === '' &&
        card[`${name}_area`] === '' &&
        card[`${name}_first`] === '' &&
        card[`${name}_last`] === ''
      ) {
        card[name] = '';
        formikProps.setFieldValue('card', card);
      }
      if(theEvent.preventDefault) theEvent.preventDefault();
    } else {
      card[specificName] = evt.target.value;
      card[name] = `${card[`${name}_area`]}.${card[`${name}_first`]}.${card[`${name}_last`]}`;
      formikProps.setFieldValue('card', card);
      if (length !== undefined && evt.target.value.length >= length) {
        evt.target.nextElementSibling.focus();
      }
    }
  }

  renderPhone(name, formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    return (
      <div className="card-form-phone-flex">
        <Field name={`card[${name}]`} type="hidden" />
        <Field name={`card[${name}_area]`} className="card-form-phone-flex-static" maxLength={"3"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_area`, formikProps, 3)} />
        <Field name={`card[${name}_first]`} className="card-form-phone-flex-static" maxLength={"3"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_first`, formikProps, 3)} />
        <Field name={`card[${name}_last]`} className="card-form-phone-flex-fill" maxLength={"4"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_last`, formikProps)} />
      </div>
    )
  }

  renderRadioBox(name, formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;

    if (this.readonly()) {
      return (
        <Field placeholder=" " className={`form-control ${erroring ? 'field-erroring': "" }`} readonly="readonly" name={`card[${name}]`}/>
      );
    }

    let erroring = checkError && (formikProps.values.card[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    const radioValues = properties['values'].map(value =>
      <label className="radio-container">
        {value}
        <Field type="radio" name={`card[${name}]`} value={value} checked={parseInt(formikProps.values.card[name]) === value} />
        <span class="checkmark"></span>
      </label>
    );

    return (
      <div role="group" aria-labelledby="my-radio-group">
        {radioValues}
      </div>
    );
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring =  checkError && ((formikProps.values['card'][name] == null || formikProps.values.card[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['card'][name])[0]
    }catch (e) {

    }

    if (this.readonly()) {
      return (
        <Field placeholder=" " className={`form-control ${erroring ? 'field-erroring': "" }`} readonly="readonly" name={`card[branchid][${name}]`} value={value.label} />
      );
    }

    return (
      <Select
        className={erroring ? "sg-request-meeting-select-erroring" : "card-form-select-wrapper"}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        classNamePrefix='sg-request-meeting-select'
        defaultValue={{value:"",label:"Select branch..."}}
        value={options ? value : ""}
        name={`card[${name}]`}
        onChange={(option) => {
          const { card } = formikProps.values;
          card[name] = option.value;
          formikProps.setFieldValue('card', card)
          renderOptions['onChange'] ? renderOptions['onChange'](option,formikProps) : "";
          if(renderOptions['callback']) {
            renderOptions['callback'](formikProps);
          }
        }}
      />
    )
  }

  readonly() {
    const { card, user } = this.props;
    if (user.role === "user" && card !== undefined && card.status === "approved") {
      return true;
    }
    return false;
  }

  getUnusedKeys() {
    return [
      'work_phone_area',
      'work_phone_first',
      'work_phone_last',
      'cell_phone_area',
      'cell_phone_first',
      'cell_phone_last'
    ];
  }

  renderModal(formikProps) {
    const { user } = this.props;
    const { submitting } = this.state;
    if (!Object.entries) {
      Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i);
        while (i--)
          resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
      };
    }
    const cardValues = Object.entries(formikProps.values.card).map(([key, value]) => {
      if (key === 'tracking' && isUser(user) || this.getUnusedKeys().includes(key)) {
        return <></>;
      } else {
        return (
          <div className="card-form-entries">
            <div className="card-form-entries-label">
              {this.getLabelName(key)}
            </div>
            <div className="card-form-entries-value verdana-12-black-bold">
              {this.getValue(value, key)}
            </div>
          </div>
        );
      }
    });
    return (
      <div style={{padding: "30px 36px"}}>
        <div className="verdana-16-green" style={{marginBottom: "15px"}}>Thank you for the order</div>
        <div className="verdana-12-black">
          Please review your information below. Use the Keep Editing function to make any changes. Proofs are generated in 1-2 business days and then reviewed and approved by a Graniterock Admin.
          <br /><br/>
          {cardValues}
        </div>
        <div style={{marginBottom: "20px"}}>
          <button className="default-button gillsans-14-white" style={{marginTop:"20px"}} type="button" onClick={() => this.formikSubmit(formikProps)}>
            Submit
          </button>
          <button className="default-button-cancel gillsans-14-black" style={{background: "white", color: "#0D274D", marginTop:"20px"}} type="button" onClick={this.toggleModal}>
            Keep Editing
          </button>
        </div>
        {
          submitting ?
          <div className="loading-overlay">
            <div className="loading-icon-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>
          :
          ''
        }
      </div>
    );
  }

  renderFormContent(formikProps) {
    const { user, branches } = this.props;
    const { initialValues } = this.state;
    return (
      <div>
        <div className="form-field-group">
          <label className="verdana-12-black"> First Name* </label>
          {this.renderField('name_first',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Last Name* </label>
          {this.renderField('name_last',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Job Title 1* </label>
          {this.renderField('job_title_1',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Job Title 2 </label>
          {this.renderField('job_title_2',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Email* </label>
          {this.renderField('email',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Direct Line/VM </label>
          {this.renderPhone('work_phone',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Cell </label>
          {this.renderPhone('cell_phone',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Branch* </label>
          {this.renderSelectField('branch_id', formikProps, this.getBranchOptions())}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Notes </label>
          {this.renderField('notes',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Quantity* </label>
          {this.renderRadioBox('quantity',formikProps, false, {values: [250, 500]})}
        </div>
        {
          isJaguar(user) ?
          <div className="form-field-group">
            <label className="verdana-12-black"> Tracking </label>
            {this.renderField('tracking',formikProps)}
          </div>
          :
          <>{this.renderField('tracking',formikProps, false, {type: 'hidden'})}</>
        }
        <div className="form-field-group">
          <label></label>
          {
            this.readonly() ?
            <></>
            :
            <div>
              {
                formikProps.dirty || initialValues["card"]["branch_id"] !== formikProps.values["card"]["branch_id"] ?
                <button className="default-button gillsans-14-white" style={{marginBottom:"50px"}} type="button" onClick={() => this.submitForm(formikProps)}>
                  Submit
                </button>
                :
                <button className="default-button-cancel gillsans-14-black" style={{marginBottom:"50px", marginLeft: "0px", cursor: "default"}} type="button">
                  Submit
                </button>
              }
              <button className="default-button-cancel gillsans-14-black" style={{background: "white", color: "#0D274D", marginBottom:"50px"}} type="button" onClick={() => this.cancelForm(formikProps)}>
                Cancel
              </button>
            </div>
          }
        </div>
      </div>
    )
  }

  renderCardBackSelect() {
    const { cardBackOpen, cardBack } = this.state;
    const renderedOptions = cardBackOptions().map(option =>
      <div className="card_back_dropdown_option" onClick={() => this.setCardBack(option)}>
        {option.label}
      </div>
    );
    return (
      <div className="card-form-image-enlarge" style={{display: "flex", justifyContent: "space-between"}}>
        <div className="verdana-12-black">
          Back
        </div>
        <div className="verdana-11-orange card_back_dropdown" onClick={this.toggleCardBack} ref={this.wrapperRef}>
          {cardBackOptions().find(option => option.value === cardBack).label}
          <div className="card-back-triangle" />
          <div className="card_back_dropdown_wrapper" style={cardBackOpen ? {display: "block"} : {display: "none"}}>
            {renderedOptions}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { card, user, preview } = this.props;
    const { modalOpen, previewModalOpen, imageFront, imageBack, cardBack, initialValues } = this.state;
    const initialV = {
      card: {
        name_first: card ? card.name_first : "",
        name_last: card ? card.name_last : "",
        job_title_1: card ? card.job_title_1 : "",
        job_title_2: card ? card.job_title_2 : "",
        email: card ? card.email : "",
        work_phone: card ? card.work_phone : "",
        work_phone_area: card && card.work_phone ? card.work_phone.split('.')[0] : "",
        work_phone_first: card && card.work_phone ? card.work_phone.split('.')[1] : "",
        work_phone_last: card && card.work_phone ? card.work_phone.split('.')[2] : "",
        cell_phone: card ? card.cell_phone : "",
        cell_phone_area: card && card.cell_phone ? card.cell_phone.split('.')[0] : "",
        cell_phone_first: card && card.cell_phone ? card.cell_phone.split('.')[1] : "",
        cell_phone_last: card && card.cell_phone ? card.cell_phone.split('.')[2] : "",
        branch_id: card ? (card.branch ? card.branch.id : "" ): "",
        notes: card ? card.notes : "",
        quantity: card ? card.quantity : 250,
        tracking: card ? card.tracking : ""
      }
    }
    return (
      <>
        <div className="form-header">
          <span className="verdana-16-green">Business Card Form</span>
          <br />
          <span className="verdana-12-black">Input information exactly as you wish it to appear on your card. Mandatory fields marked with an asterisk.</span>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <Formik
              initialValues={initialV}
              onSubmit={(values, actions) => {

                const cookies = new Cookies();
                const token = cookies.get("X-CSRF-Token");

                var formElement = document.querySelector("#sg-meeting-request-form");
                var data = new FormData(formElement);
                if (imageFront !== null) {
                  data.append("card[preview_front]", imageFront);
                }
                data.append("card[card_back]",cardBack);
                data.append("user_id", user.id);

                const changedValues = []
                for (const [key, value] of Object.entries(values["card"])) {
                  if (value !== initialValues["card"][key]) {
                    changedValues.push({"key": key, "value": value});
                  }
                }
                if (changedValues != []) {
                  data.append("changed_values", JSON.stringify(changedValues));
                }

                fetch(this.formUrl(), {
                  method: this.formMethod(),
                  redirect: "manual",
                  body: data,
                  headers: {
                    "X-CSRF-Token": token
                  }
                })
                  .then(response => {
                    console.log(response);
                    return response.json();
                  })
                  .then(json => {
                    if(json.error == null) {
                      this.setState({ submitting: false });
                      window.location = "/";
                    }else {
                      console.log(json.error);
                    }
                  });
              }}
              render={(formikProps) => (
                <>
                  <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="sg-meeting-request-form">
                    {this.renderFormContent(formikProps)}
                  </Form>
                  <Modal
                    className="general-modal general-modal-730"
                    overlayClassName="modal-overlay"
                    isOpen={modalOpen}
                    onRequestClose={this.toggleModal}
                    contentLabel=""
                    style={{backgroundColor: "#00BCEB"}}
                  >
                    {this.renderModal(formikProps)}
                  </Modal>
                </>
              )}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="card-form-status">
              <div className="card-form-status-label verdana-12-black">Status:</div>
              {card ? <div className={`status-wrapper status-wrapper-${card.status} verdana-11-white`}>{getStatusValue(card.status)}</div> : <div className="status-wrapper status-wrapper-new_card verdana-11-white">New</div>}
            </div>
            <CardImageUpload isFront={true} setImage={this.imageFrontSet} previewImage={card && card.preview_front ? preview : null} user={user} />
            {
              //card && card.preview_front ?
              //<div className="card-form-image-enlarge verdana-12-orange">
              //  <a onClick={this.togglePreviewModal}>Enlarge</a>
              //</div>
              //:
              //""
              card && card.preview_front ?
              <div className="card-form-image-enlarge verdana-12-orange">
                <a href={card.preview_front} target="_blank">Download</a>
              </div>
              :
              ""
            }
            <CardImageUpload isFront={false} setImage={this.imageBackSet} previewImage={imageBack} key="image-card-back" />
            {this.renderCardBackSelect()}
            <br />
            {
              card ?
              <CardHistory history={card.card_histories} user={user} />
              : ""
            }
          </div>
        </div>
        { previewModalOpen ? <CardImagePreview closeModal={this.togglePreviewModal} previewImage={card && card.preview_front ? preview : null} /> : <></> }
      </>
    );
  }
}

export default CardForm;
