import React from "react";
import PropTypes from "prop-types";
import { isUser, isJaguar } from "../utility/UserUtils";
import { isNewCard, isOnProof, isChanged, isApproved } from "../utility/CardUtils";
import Cookies from "universal-cookie";
import Modal from "react-modal";

import CardActionRequestChange from './CardActionRequestChange';
import CardActionJaguarView from './CardActionJaguarView';

class CardActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionIsOpen: false,
      deleteModalOpen: false
    };
    this.wrapperRef = React.createRef();
    this.toggleActionIsOpen = this.toggleActionIsOpen.bind(this);
    this.toggleDeleteModalOpen = this.toggleDeleteModalOpen.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.unApprove = this.unApprove.bind(this);
    this.approve = this.approve.bind(this);
    this.download = this.download.bind(this);
    this.delete = this.delete.bind(this);
    this.setHandleClickOutside = this.setHandleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef && !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({actionIsOpen: false});
    }
  }

  setHandleClickOutside(on) {
    if (on) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  toggleActionIsOpen() {
    const { actionIsOpen } = this.state;
    // this.setHandleClickOutside(false);
    this.setState({ actionIsOpen: !actionIsOpen });
  }

  toggleDeleteModalOpen() {
    const { deleteModalOpen } = this.state;
    this.setState({ deleteModalOpen: !deleteModalOpen });
  }

  unApprove() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}/un_approve`), {
      method: 'POST',
      redirect: "manual",
      body: '',
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  approve() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}/approve`), {
      method: 'POST',
      redirect: "manual",
      body: '',
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  download() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}/download`), {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log('success');
        // location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  delete() {
    const { card } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch((`/cards/${card.id}`), {
      method: 'DELETE',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "content-type": "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.error == null) {
        console.log('success');
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  renderDownloadButton() {
    const { card } = this.props;
    if (card.thumb.includes('missing.png')) {
      return '';
    }
    return (
      <a href={`/cards/${card.id}/download`}>
        <div className="card-table-actions-popup-action">
          Download
        </div>
      </a>
    );
  }

  renderApproveButton() {
    const { user, card } = this.props;
    if (!isUser(user)) {
      if (isNewCard(card.status)) {
        return (
          <div className="card-table-actions-button-wrapper">
            <div className="default-button-mui-option default-button-mui-option-grey gillsans-14-grey">Approve</div>
          </div>
        );
      } else if (isApproved(card.status)) {
        return (
          <div className="card-table-actions-button-wrapper">
            <a onClick={this.unApprove}><div className="default-button-mui-option default-button-mui-option-green gillsans-14-white">Unapprove</div></a>
          </div>
        );
      } else if (isOnProof(card.status) || isChanged(card.status)) {
        return (
          <div className="card-table-actions-button-wrapper">
            <a onClick={this.approve}><div className="default-button-mui-option default-button-mui-option-orange gillsans-14-white">Approve</div></a>
          </div>
        );
      }
      return (
        <div className="card-table-actions-button-wrapper">
          <a><div className="default-button-mui-option gillsans-14-orange">Approve</div></a>
        </div>
      );
    }
    return '';
  }

  renderViewButton() {
    const { user, card } = this.props;
    if (isJaguar(user)) {
      return (
        <CardActionJaguarView card={card} type="button" key={`card-action-view-${card.id}`} modalOnLoad={true}/>
      )
    }
    return (
      <div className="card-table-actions-button-wrapper">
        <a href={`/cards/${card.id}`}><div className="default-button-mui-option gillsans-14-orange">Edit/View</div></a>
      </div>
    )
  }

  renderJaguarButtons() {
    const { user, card } = this.props;
    if (isJaguar(user)) {
      return (
        <>
          <a href={`/cards/${card.id}`}>
            <div className="card-table-actions-popup-action">
              Edit
            </div>
          </a>
          <a href="#">
            <div className="card-table-actions-popup-action">
              Tracking
            </div>
          </a>
          <a onClick={this.toggleDeleteModalOpen}>
            <div className="card-table-actions-popup-action">
              Delete
            </div>
          </a>
        </>
      );
    }
    return '';
  }

  renderDeleteModal() {
    const { card } = this.props;
    return (
      <div style={{padding: "40px 58px"}} className="card-table-actions-jaguar-view-wrapper">
        <div style={{display: "flex", marginBottom: "10px"}}>
          <div className="verdana-16-green">Are you sure you want to delete this card?</div>
        </div>
        <br />
        <div style={{display: "flex"}}>
          <a onClick={() => this.delete(card.id)} style={{cursor: "pointer"}}>
            <div className="default-button-mui-option default-button-mui-option-orange gillsans-14-white" style={{margin: "auto", marginRight: "10px"}}>
              Delete
            </div>
          </a>
          <a onClick={this.toggleDeleteModalOpen} style={{cursor: "pointer"}}>
            <div className="default-button-mui-option default-button-mui-option-grey gillsans-14-white" style={{margin: "auto", cursor: "pointer"}}>
              Cancel
            </div>
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { user, card } = this.props;
    const { actionIsOpen, deleteModalOpen } = this.state;
    return (
      <>
        <div key={`card-table-actions-wrapper-${card.id}`} className="card-table-actions-wrapper" ref={this.wrapperRef}>
          {this.renderApproveButton()}
          {this.renderViewButton()}
          <div className="card-table-actions-button-wrapper">
            <a onClick={this.toggleActionIsOpen}><div className="default-button-mui-option gillsans-14-orange">More Actions</div></a>
          </div>
          {
            actionIsOpen ?
            <div className="card-table-actions-popup-wrapper">
              {this.renderDownloadButton()}
              <CardActionRequestChange user={user} card={card} setHandleClickOutside={this.setHandleClickOutside} />
              {this.renderJaguarButtons()}
            </div>
            :
            ''
          }
        </div>
        <Modal
          className="general-modal add-request-modal"
          overlayClassName="modal-overlay"
          isOpen={deleteModalOpen}
          onRequestClose={this.toggleDeleteModalOpen}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderDeleteModal()}
        </Modal>
      </>
    );
  }
}

export default CardActionButton;
