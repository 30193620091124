import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserTableEdit from "./UserTableEdit";
import UserTableToolbar from "./UserTableToolbar";
import CardTableFooter from "../cards/CardTableFooter";

import { DateTime } from "luxon";

import { getRoleValue } from "../utility/UserUtils";
import { getMuiTheme } from "../utility/MuiUtils";
import { formatDate } from "../utility/DateUtils";

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredUsers: [],
      rowsPerPage: 5,
      pageNumber: 0
    };
    this.renderToolbar = this.renderToolbar.bind(this);
    this.setRole = this.setRole.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
  }

  componentDidMount() {
    const { users } = this.props;
    let filteredUsers = users.map(user =>
      [
        user.user.name_first ? user.user.name_first : '',
        user.user.name_last ? user.user.name_last : '',
        user.user.email ? user.user.email : '',
        user.user.role ? this.renderUserRole(user.user.role) : '',
        user.current_sign_in_at ? formatDate(user.current_sign_in_at) : '',
        user.action ? user.action : <UserTableEdit user={user.user} key={`user-id-${user.user.id}`} />,
        user.user.role ? user.user.role : ''
      ]
    )
    this.setState({
      filteredUsers: filteredUsers,
      organizedUsers: filteredUsers
    });
  }

  setRowsPerPage(option) {
    this.setState({
      rowsPerPage: option,
      pageNumber: 0
    });
  }

  setPageNumber(option) {
    this.setState({ pageNumber: option });
  }

  setRole(option) {
    const { organizedUsers } = this.state;
    const filteredUsers = [];
    if (option === "All Roles") {
      this.setState({ filteredUsers: organizedUsers });
    } else {
      organizedUsers.forEach(user => {
        if (user[6].includes(option)) {
          filteredUsers.push(user);
        }
      });
      this.setState({ filteredUsers: filteredUsers });
    }
  }

  setSearch(value) {
    const { organizedUsers } = this.state;
    const filteredUsers = [];
    organizedUsers.forEach(user => {
      let toBeAdded = true;
      user.forEach(field => {
        if (toBeAdded) {
          try {
            if (field.toString().toLowerCase().includes(value.toString().toLowerCase())) {
              filteredUsers.push(user);
              toBeAdded = false;
            }
          } catch (error) {
          }
        }
      });
    });
    this.setState({ filteredUsers: filteredUsers });
  }

  renderUserRole(role) {
    return (
      <div className={`status-wrapper status-wrapper-${role} verdana-11-white`}>
        {getRoleValue(role)}
      </div>
    );
  }

  renderToolbar(props) {
    const { filteredUsers } = this.state;
    return <UserTableToolbar {...props} value={2} setRole={this.setRole} setSearch={this.setSearch} users={filteredUsers} />;
  }
  renderFooter(props) {
    const { organizedCards, pageNumber } = this.state;
    return <CardTableFooter {...props} value={2} setRowsPerPage={this.setRowsPerPage} setPageNumber={this.setPageNumber} pageNumber={pageNumber} setSearch={this.setSearch} cards={organizedCards} />;
  }

  render() {
    const { filteredUsers, rowsPerPage, pageNumber } = this.state;
    const columns = [
      'First',
      'Last',
      'Email',
      'Role',
      'Last Active',
      'Action'
    ];
    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={filteredUsers}
          columns={columns}
          options={{
            selectableRows: 'none',
            search: true,
            searchOpen: true,
            rowsPerPage: rowsPerPage,
            page: pageNumber,
          }}
          components={{
            TableToolbar: this.renderToolbar,
            TableFooter: this.renderFooter
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default UserTable;
