import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class CardActionJaguarViewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false
    };
    this.isCopiedOn = this.isCopiedOn.bind(this);
    this.isCopiedOff = this.isCopiedOff.bind(this);
  }

  isCopiedOn() {
    this.setState({isCopied: true});
  }
  isCopiedOff() {
    this.setState({isCopied: false});
  }

  copyToClip(value) {
    this.isCopiedOn();
    setTimeout(() => { this.isCopiedOff() }, 3000);
    console.log(value);
    navigator.clipboard.writeText(value);
  }

  renderCopyToClip() {
    const { isCopied } = this.state;
    return (
      <div className={`card-info-copied ${isCopied ? 'card-info-copied-show' : 'card-info-copied-hide' } verdana-12-orange`}>Copied.</div>
    );
  }

  render() {
    const { card, field } = this.props;
    const { isCopied } = this.state;
    return (
      <div className="card-info-wrapper">
        <div className="card-info-label verdana-12-grey">{field.label}</div>
        <div className="card-info-value verdana-12-black" onClick={() => this.copyToClip(field.value)}>{field.value}</div>
        {this.renderCopyToClip()}
      </div>
    );
  }
}

export default CardActionJaguarViewDetails;
