import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DateTime } from "luxon";
import Modal from "react-modal";
import Cookies from "universal-cookie";

import CardTableFooter from '../cards/CardTableFooter';
import { getStatusValue } from "../utility/CardUtils";
import { getMuiTheme } from "../utility/MuiUtils";
import { formatDate } from "../utility/DateUtils";

class ReportsTableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      rowsPerPage: 5,
      pageNumber: 0,
      organizedCards: []
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
  }

  componentDidMount() {
    const { cards, card_ids } = this.props;
    let organizedCards = cards.filter(card => card_ids.includes(card.id)).map(card =>
      [
        card.thumb ? this.renderPreview(card.thumb) : '',
        card.created_at ? formatDate(card.created_at) : '',
        card.name_first ? card.name_first : '',
        card.name_last ? card.name_last : '',
        card.quantity ? card.quantity : '',
        card.tracking ? card.tracking : '',
        card.status ? this.renderStatus(card.status) : '',
      ]
    )
    this.setState({
      organizedCards: organizedCards,
      filteredCards: organizedCards
    });
  }

  setRowsPerPage(option) {
    this.setState({
      rowsPerPage: option,
      pageNumber: 0
    });
  }

  setPageNumber(option) {
    this.setState({ pageNumber: option });
  }

  renderPreview(preview) {
    return (
      <img className="mui-preview-image" src={preview} />
    );
  }

  renderStatus(status) {
    return (
      <div className={`status-wrapper status-wrapper-${status} verdana-11-white`}>
        {getStatusValue(status)}
      </div>
    );
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  renderHeader(props) {
    return <></>;
  }

  renderFooter(props) {
    const { organizedCards, pageNumber } = this.state;
    return <CardTableFooter {...props} setRowsPerPage={this.setRowsPerPage} setPageNumber={this.setPageNumber} pageNumber={pageNumber} cards={organizedCards} />;
  }

  renderModal() {
    const { card_ids, cards } = this.props;
    const { filteredCards, rowsPerPage, pageNumber } = this.state;
    const columns = [
      'Preview',
      'Date Ordered',
      'First',
      'Last',
      'Qty',
      'Tracking',
      'Status'
    ];

    return (
      <div style={{padding: "0px 20px"}}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={filteredCards}
            columns={columns}
            options={{
              selectableRows: 'none',
              search: true,
              searchOpen: true,
              rowsPerPage: rowsPerPage,
              page: pageNumber,
              elevation: 0
            }}
            components={{
              TableToolbar: this.renderHeader,
              TableFooter: this.renderFooter,
            }}
          />
        </MuiThemeProvider>
      </div>
    )
  }

  getDownloadUrl() {
    const { card_ids } = this.props;
    return `/admin/download_report?card_ids=${card_ids.join('.')}`
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <a onClick={this.toggleModal} style={{cursor: "pointer"}}>View</a>
        &nbsp;|&nbsp;
        <a href={this.getDownloadUrl()} style={{cursor: "pointer"}}>Download</a>
        <Modal
          className="general-modal"
          overlayClassName="modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
          style={{backgroundColor: "#00BCEB"}}
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ReportsTableView;
