import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";

class BranchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.setConstruction = this.setConstruction.bind(this);
  }

  setConstruction(value, formikProps) {
    const { branch } = formikProps.values;
    branch['construction_division'] = value;
    formikProps.setFieldValue('branch', branch);
  }

  formUrl() {
    const { branch } = this.props;
    if (branch !== undefined) {
      return `/branches/${branch.id}`;
    }
    return '/branches';
  }

  formMethod() {
    const { branch } = this.props;
    if (branch !== undefined) {
      return 'PATCH';
    }
    return 'POST';
  }

  cancelForm(formikProps) {
    window.location = "/branches";
  }

  submitForm(formikProps) {
    const { values } = formikProps;
    formikProps.handleSubmit()
  }

  renderField(name, formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.branch[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field placeholder=" " onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`branch[${name}]`}/>
    )
  }

  validateNumber(evt, name, specificName, formikProps, length) {
    const { branch } = formikProps.values;
    var theEvent = evt || window.event;

    let key = evt.target.value
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if (
        key === '' &&
        branch[`${name}_area`] === '' &&
        branch[`${name}_first`] === '' &&
        branch[`${name}_last`] === ''
      ) {
        branch[name] = '';
        formikProps.setFieldValue('branch', branch);
      }
      if(theEvent.preventDefault) theEvent.preventDefault();
    } else {
      branch[specificName] = evt.target.value;
      branch[name] = `${branch[`${name}_area`]}.${branch[`${name}_first`]}.${branch[`${name}_last`]}`;
      formikProps.setFieldValue('branch', branch);
      if (length !== undefined && evt.target.value.length >= length) {
        evt.target.nextElementSibling.focus();
      }
    }
  }

  renderPhone(name, formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    return (
      <div className="card-form-phone-flex">
        <Field name={`branch[${name}]`} type="hidden" />
        <Field name={`branch[${name}_area]`} className="card-form-phone-flex-static" maxLength={"3"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_area`, formikProps, 3)} />
        <Field name={`branch[${name}_first]`} className="card-form-phone-flex-static" maxLength={"3"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_first`, formikProps, 3)} />
        <Field name={`branch[${name}_last]`} className="card-form-phone-flex-fill" maxLength={"4"} onKeyUp={(e) => this.validateNumber(e, name, `${name}_last`, formikProps)} />
      </div>
    )
  }

  renderFormContent(formikProps) {
    const { user } = this.props;
    const { branch } = formikProps.values;
    console.log(formikProps);
    return (
      <div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Name </label>
          {this.renderField('name',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Address </label>
          {this.renderField('address',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> City </label>
          {this.renderField('city',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> State </label>
          {this.renderField('state',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Zip </label>
          {this.renderField('zip',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Phone </label>
          {this.renderPhone('phone',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Fax </label>
          {this.renderPhone('fax',formikProps)}
        </div>
        <div className="form-field-group">
          <label className="verdana-12-black"> Is this a Construction Division Branch? </label>
          <div className="form-field-radio-wrapper">
            <div className="form-field-radio-inner">
              <label for="construction_division_yes" className="verdana-12-black">Yes</label>
              <input type="radio" id="construction_division_yes" name="branch[construction_division]" value={true} checked={branch["construction_division"]} onClick={() => this.setConstruction(true, formikProps)} />
            </div>
            <div className="form-field-radio-inner">
              <label for="construction_division_no" className="verdana-12-black">No</label>
              <input type="radio" id="construction_division_no" name="branch[construction_division]" value={false} checked={!branch["construction_division"]} onClick={() => this.setConstruction(false, formikProps)} />
            </div>
          </div>
        </div>
        <div className="form-field-group">
          <label></label>
          <div>
            <button className="default-button gillsans-14-white" style={{marginBottom:"50px"}} type="button" onClick={() => this.submitForm(formikProps)}>
              Submit
            </button>
            <button className="default-button-cancel gillsans-14-black" style={{background: "white", color: "#0D274D", marginBottom:"50px"}} type="button" onClick={() => this.cancelForm(formikProps)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { branch, user } = this.props;

    return (
      <>
        <div className="form-header">
          {
            branch ?
            <span className="verdana-16-green">Edit Branch: {`${branch.name}`}</span>
            :
            <span className="verdana-16-green">New Branch</span>

          }
          <br />
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <Formik
              initialValues={{
                branch: {
                  name: branch ? branch.name : "",
                  address: branch ? branch.address : "",
                  city: branch ? branch.city : "",
                  state: branch ? branch.state : "",
                  zip: branch ? branch.zip : "",
                  phone: branch ? branch.phone : "",
                  phone_area: branch && branch.phone ? branch.phone.split('.')[0] : "",
                  phone_first: branch && branch.phone ? branch.phone.split('.')[1] : "",
                  phone_last: branch && branch.phone ? branch.phone.split('.')[2] : "",
                  fax: branch ? branch.fax : "",
                  fax_area: branch && branch.fax ? branch.fax.split('.')[0] : "",
                  fax_first: branch && branch.fax ? branch.fax.split('.')[1] : "",
                  fax_last: branch && branch.fax ? branch.fax.split('.')[2] : "",
                  construction_division: branch ? branch.construction_division : false
                }
              }}
              onSubmit={(values, actions) => {

                const cookies = new Cookies();
                const token = cookies.get("X-CSRF-Token");

                var formElement = document.querySelector("#sg-meeting-request-form");
                var data = new FormData(formElement);

                fetch(this.formUrl(), {
                  method: this.formMethod(),
                  redirect: "manual",
                  body: data,
                  headers: {
                    "X-CSRF-Token": token
                  }
                })
                  .then(response => {
                    return response.json();
                  })
                  .then(json => {
                    if(json.error == null) {
                      window.location = "/branches";
                    }else {
                    }
                  });
              }}
              render={(formikProps) => (
                <>
                  <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="sg-meeting-request-form">
                    {this.renderFormContent(formikProps)}
                  </Form>
                </>
              )}
            />
          </div>
        </div>
      </>
    );
  }
}

export default BranchForm;
