import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';

import { getMuiTheme } from "../utility/MuiUtils";
import { formatDateMonthYear, formatDateYear } from "../utility/DateUtils";

import ReportsTableToolbar from './ReportsTableToolbar';
import ReportsTableView from './ReportsTableView';
import CardTableFooter from '../cards/CardTableFooter';

class ReportsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizedCards: [],
      rowsPerPage: 5,
      pageNumber: 0,
      years: []
    };
    this.setYear = this.setYear.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  componentDidMount() {
    const { cards } = this.props;
    let groupedCards = {};
    const years = [];

    cards.forEach(card => {
      let cardMonthYear = formatDateMonthYear(card.created_at);
      years.push(formatDateYear(card.created_at))
      if (groupedCards[cardMonthYear] === undefined) {
        groupedCards[cardMonthYear] = { month: cardMonthYear, count: 1, card_ids: [card.id], date: card.created_at };
      } else {
        groupedCards[cardMonthYear]["count"] = groupedCards[cardMonthYear]["count"] + 1;
        groupedCards[cardMonthYear]["card_ids"].push(card.id);
      }
    });

    let filteredCards = Object.keys(groupedCards).map(cardGroup =>
      [
        groupedCards[cardGroup]["month"],
        groupedCards[cardGroup]["count"],
        <ReportsTableView card_ids={groupedCards[cardGroup]["card_ids"]} cards={cards} />
      ]
    );

    this.setState({
      filteredCards: filteredCards,
      organizedCards: filteredCards,
      years: [...new Set(years)]
    });
  }

  setYear(option) {
    const { organizedCards } = this.state;
    const filteredCards = [];
    if (option === "All Years") {
      this.setState({ filteredCards: organizedCards });
    } else {
      organizedCards.forEach(card => {
        if (card[0].includes(option)) {
          filteredCards.push(card);
        }
      });
      this.setState({ filteredCards: filteredCards });
    }
  }

  renderToolbar(props) {
    const { organizedCards, years } = this.state;
    return <ReportsTableToolbar {...props} setYear={this.setYear} cards={organizedCards} years={years} />;
  }
  renderFooter(props) {
    const { organizedCards, pageNumber } = this.state;
    return <CardTableFooter {...props} setRowsPerPage={this.setRowsPerPage} setPageNumber={this.setPageNumber} pageNumber={pageNumber} cards={organizedCards} />;
  }

  render() {
    const { filteredCards, rowsPerPage, pageNumber } = this.state;
    const columns = [
      'Month',
      'Total Cards Ordered',
      'Action'
    ];
    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={filteredCards}
          columns={columns}
          options={{
            selectableRows: 'none',
            search: true,
            searchOpen: true,
            rowsPerPage: rowsPerPage,
            page: pageNumber,
          }}
          components={{
            TableToolbar: this.renderToolbar,
            TableFooter: this.renderFooter
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default ReportsTable;
