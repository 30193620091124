import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from "../utility/MuiUtils";
import { formatDate, formatDateYear } from "../utility/DateUtils";

import CardTableToolbar from "../cards/CardTableToolbar";
import CardTableFooter from "../cards/CardTableFooter";

class BranchTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizedBranches: [],
      rowsPerPage: 5,
      pageNumber: 0,
      years: []
    };
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setSearch = this.setSearch.bind(this);
  }

  componentDidMount() {
    const { branches } = this.props;
    const years = [];
    let filteredBranches = this.mapBranchesToColumns(branches);
    branches.forEach(branch => {
      years.push(formatDateYear(branch.created_at))
    });
    this.setState({
      organizedBranches: filteredBranches,
      filteredBranches: filteredBranches,
      years: [...new Set(years)]
    });
  }

  setRowsPerPage(option) {
    this.setState({
      rowsPerPage: option,
      pageNumber: 0
    });
  }

  setPageNumber(option) {
    this.setState({ pageNumber: option });
  }

  mapBranchesToColumns(branches) {
    return (
      branches.map(branch =>
        [
          branch.name ? <div className="verdana-12-black">{branch.name}</div> : '',
          <div className="verdana-12-black">{`${branch.address}, ${branch.city} ${branch.state}, ${branch.zip}`}</div>,
          <div className="verdana-12-green">{formatDate(branch.created_at)}</div>,
          this.renderBranchActions(branch),
          branch.created_at
        ]
      )
    );
  }

  setYear(option) {
    const { organizedBranches } = this.state;
    const filteredBranches = [];
    if (option === "All Years") {
      this.setState({ filteredBranches: organizedBranches });
    } else {
      organizedBranches.forEach(branch => {
        if (branch[4].includes(option)) {
          filteredBranches.push(branch);
        }
      });
      this.setState({ filteredBranches: filteredBranches });
    }
  }

  setSearch(value) {
    const { branches } = this.props;
    const filteredBranches = [];
    branches.forEach(branch => {
      let toBeAdded = true;
      Object.entries(branch).forEach(field => {
        if (toBeAdded) {
          try {
            if (field[1].toString().toLowerCase().includes(value.toString().toLowerCase())) {
              filteredBranches.push(branch);
              toBeAdded = false;
            }
          } catch (error) {
          }
        }
      });
    });
    this.setState({ filteredBranches: this.mapBranchesToColumns(filteredBranches) });
  }

  deleteBranch(id) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/branches/${id}`, {
      method: 'DELETE',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        console.log(response);
        return response.json();
      })
      .then(json => {
        if(json.error == null) {
          window.location.reload();
        }else {
          console.log(json.error);
        }
      });
  }

  renderBranchActions(branch) {
    const { user } = this.props;
    return (
      <div className="card-table-actions-wrapper" ref={this.wrapperRef}>
        <div className="card-table-actions-button-wrapper">
          <a href={`/branches/${branch.id}`}><div className="default-button-mui-option gillsans-14-orange">Edit</div></a>
        </div>
        <div className="card-table-actions-button-wrapper">
          <a onClick={() => this.deleteBranch(branch.id)}><div className="default-button-mui-option gillsans-14-orange">Delete</div></a>
        </div>
      </div>
    );
  }

  renderToolbar(props) {
    const { organizedBranches, years } = this.state;
    // setYear={this.setYear}
    return <CardTableToolbar {...props} value={2} setSearch={this.setSearch} cards={organizedBranches} isBranch={true} years={years} />;
  }
  renderFooter(props) {
    const { organizedBranches, pageNumber } = this.state;
    return <CardTableFooter {...props} setRowsPerPage={this.setRowsPerPage} setPageNumber={this.setPageNumber} pageNumber={pageNumber} cards={organizedBranches} />;
  }

  render() {
    const { filteredBranches, rowsPerPage, pageNumber } = this.state;
    const columns = [
      {
        name: "Title",
        label: "Title",
        options: {
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {return data}
        }
      },
      {
        name: "Address",
        label: "Address",
        options: {
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {return data}
        }
      },
      {
        name: "Created At",
        label: "Created At",
        options: {
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {return data}
        }
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          setCellProps: () => ({ style: { width: "120px" }}),
          customHeadRender: ()=>null,
          customBodyRender: (data, type, row) => {return data}
        }
      }
    ];

    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={filteredBranches}
          columns={columns}
          options={{
            selectableRows: 'none',
            search: true,
            searchOpen: true,
            rowsPerPage: rowsPerPage,
            page: pageNumber,
          }}
          components={{
            TableToolbar: this.renderToolbar,
            TableFooter: this.renderFooter
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default BranchTable;
