import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

class CardTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: 'All Years',
      dropdownOpen: false
    };
    this.searchBarInput = this.searchBarInput.bind(this);
    this.setSelectedYear = this.setSelectedYear.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  setSelectedYear(option) {
    const { setYear } = this.props;
    setYear(option);
    this.setState({ selectedYear: option });
  }

  searchBarInput(e) {
    const { setSearch } = this.props;
    setSearch(e.target.value);
  }

  renderDropdownOptions() {
    const { cards, years } = this.props;
    const { dropdownOpen } = this.state;
    const dropdownOptions = years.map(year =>
      <div className="toolbar-dropdown-option" key={`toolbar-dropdown-${year}`} onClick={() => this.setSelectedYear(year)}>{year}</div>
    );
    if (dropdownOpen) {
      return (
        <div className="toolbar-dropdown-options">
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedYear('All Years')}>All Years</div>
          {dropdownOptions}
          <div className="toolbar-dropdown-option" onClick={() => this.setSelectedYear('2020')}>2020</div>
        </div>
      );
    }
    return '';
  }

  render() {
    const { isBranch, setYear } = this.props;
    const { selectedYear } = this.state;
    return (
      <div className="mui-toolbar">
        {
          setYear === undefined ?
          ''
          :
          <>
            <div className="toolbar-label verdana-11-black">Show:</div>
            <div className="toolbar-dropdown verdana-11-black" onClick={this.toggleDropdown}>
              {selectedYear}
              <svg height="6" width="9" style={{marginLeft: "8px"}}>
                <polygon points="0,0 9,0 4.5,6" style={{fill: "black"}}></polygon>
                Sorry, your browser does not support inline SVG.
              </svg>
              {this.renderDropdownOptions()}
            </div>
          </>
        }
        <div className="toolbar-search" style={setYear === undefined ? {marginLeft: '0px'} : {}}>
          <input type="text" placeholder="Search" className="verdana-12-black" onChange={this.searchBarInput} />
        </div>
        <div className="new-card-button gillsans-14-white">
          {
            isBranch ?
            <a className="default-button" href="/branches/new">New Branch</a>
            :
            <a className="default-button" href="/cards/new">New Card</a>
          }
        </div>
      </div>
    );
  }
}

export default CardTableToolbar;
